.btnNone {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.btnBlueIcon {
  color: #90caf9;
}

.btnBlue {
  display: flex;
  justify-content: center;
  padding: 1em;
  background-color: #0a77cf;
  border-radius: 15px;
  color: white;
  font-size: medium;
  align-items: center;
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

.btnBlueOuter {
  display: flex;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-color: #90caf9;
  background-color: transparent;
  border-radius: 15px;
  color: white;
  font-size: medium;
  align-items: center;
  cursor: pointer;
  outline: none;
}

.btnBlueOuterLink {
  display: flex;
  justify-content: center;
  padding: 0.5rem 1rem;
  background-color: transparent;
  border-radius: 15px;
  color: white;
  font-size: small;
  align-items: center;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid #2196f3;
}

.btnBlueOuter:hover, .btnBlueOuterLink:hover {
  color: white;
  background-color: #90caf9;
}

.confirmBtnDialog {
  background-color: #90caf9 !important;
}

.danger, .danger:hover {
  background-color: #e74c3c !important;
  color: white !important;
}

.rejectBtnDialog {
  color: rgb(161, 159, 159) !important;
}

.btnLogout {
  color: white;
  display: flex;
  align-items: center;
  -webkit-margin-start: 1em;
          margin-inline-start: 1em;
}/*# sourceMappingURL=Button.module.css.map */