.sidebarHr {
  border: 1px solid rgb(43, 47, 52);
}

.link {
  color: rgb(226, 226, 226);
  text-decoration: none;
}

.fontSm {
  font-size: small !important;
}

.fontLg {
  font-size: large;
}

.darkWhiteColor {
  color: rgba(226, 226, 226, 0.6784313725);
}

.sidebarLinkActive {
  color: #90caf9;
}

.container {
  color: white;
  margin-block: 0.5em;
}

@media screen and (min-width: 600px) {
  .container {
    width: 70%;
    -webkit-margin-start: 300px;
            margin-inline-start: 300px;
    -webkit-margin-end: 0.2em;
            margin-inline-end: 0.2em;
  }
}
@media screen and (max-width: 600px) {
  .container {
    width: 100%;
    -webkit-margin-start: 0.3em;
            margin-inline-start: 0.3em;
  }
}
.backBlackLight {
  background-color: rgb(26, 32, 39);
}

.backBlackLightContainer {
  background-color: rgb(26, 32, 39);
  color: white;
}

.LightText {
  color: white;
}

.center {
  display: flex;
  justify-content: center;
}

.centerWithAlign {
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerContainer {
  margin-top: 5rem;
  margin-inline: 0.1rem;
}

.width100 {
  width: 100%;
}

.width200 {
  width: 200px;
}

.cover {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1em;
  border-radius: 10px;
}

.spanName {
  color: rgb(161, 159, 159);
}

.p1 {
  padding: 1rem;
}

.pt1 {
  padding-top: 1rem;
}

.pb1 {
  padding-bottom: 1rem;
}

.pl1 {
  padding-left: 1rem;
}

.pr1 {
  padding-right: 1rem;
}

.pi1 {
  padding-inline: 1rem;
}

.mt1 {
  margin-top: 1rem;
}

.mt2 {
  margin-top: 2rem;
}

.mb1 {
  margin-bottom: 1rem;
}

.mse1 {
  margin-inline: 1rem;
}

.flex {
  display: flex;
  align-items: center;
}

.flexWarp {
  display: flex;
  flex-direction: row;
  gap: 0.4em;
  flex-wrap: wrap;
  align-items: center;
}

.flexGrow1 {
  flex-grow: 1;
}

.textSuccess {
  color: #43f983;
}

.relative {
  position: relative;
}

.posRightTop {
  position: absolute;
  top: 0;
  right: 0;
}

.colorRed {
  color: red;
}

.noteStar {
  position: absolute;
  top: -10px;
  right: 0;
  color: red;
  font-size: x-large;
}

.txtOverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.txtCenter {
  text-align: center;
}

.CenterLoader {
  background-color: rgba(80, 80, 80, 0.8);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}/*# sourceMappingURL=Layout.module.css.map */