.loader {
  border: 1px solid #f3f3f3;
  border-radius: 50%;
  border-top: 1px;
  border-top-color: #3498db;
  border-top-style: solid; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}/*# sourceMappingURL=Loader.module.css.map */