.galleryDivImg{
    width: 100px;
    height: 100px;
    position: relative;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 10px;
    }
    button{
        position: absolute;
        top: -15px;
        right: 0;
    }
}