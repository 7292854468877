@import '../color';

.sidebarHr{
    border: 1px solid rgb(43 47 52);
}
.link{
    color: rgb(226, 226, 226);
    text-decoration: none;
}

.fontSm{
    font-size: small !important;
}
.fontLg{
    font-size: large;
}

.darkWhiteColor{
    color: #e2e2e2ad;
}

.sidebarLinkActive{
    color: $materialBlue;
}


.container {
    color: white;
    margin-block: .5em;
}

@media screen and (min-width:600px) {
    .container {
        width: 70%;
        margin-inline-start: 300px;
        margin-inline-end: .2em;

    }
}

@media screen  and (max-width:600px) {
    .container {
        width: 100%;
        margin-inline-start: .3em;
    }
}

.backBlackLight{
    background-color: $lightBlack;
}

.backBlackLightContainer{
    background-color: $lightBlack;
    color: white;
}


.LightText{
    color: white;
}
.center{
    display: flex;
    justify-content: center;
}

.centerWithAlign{
    display: flex;
    justify-content: center;
    align-items: center;
}
.innerContainer{
    margin-top: 5rem;
    margin-inline: .1rem;
}
.width100{
    width: 100%;
}
.width200{
    width: 200px;
}
.cover{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap:1em;
    border-radius: 10px;
}

.spanName{
    color: $Name;
}
.p1{padding: 1rem;}
.pt1{padding-top: 1rem;}
.pb1{padding-bottom: 1rem;}
.pl1{padding-left: 1rem;}
.pr1{padding-right: 1rem;}
.pi1{padding-inline: 1rem;}
.mt1{margin-top: 1rem;}
.mt2{margin-top: 2rem;}
.mb1{margin-bottom: 1rem;}
.mse1{margin-inline: 1rem;}
.flex{
    display: flex;
    align-items: center;
}

.flexWarp{
    display: flex;
    flex-direction: row;
    gap: .4em;
    flex-wrap: wrap;
    align-items: center;
}
.flexGrow1{
    flex-grow:1
}
.textSuccess{
    color:$success
}

.relative{
    position: relative;
}

.posRightTop{
    position: absolute;
    top: 0;
    right: 0;
}

.colorRed{
    color:red
}
.noteStar{
    position: absolute;
    top: -10px;
    right: 0; 
    color:red;
    font-size: x-large;
}
.txtOverflow{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.txtCenter{
    text-align: center;
}

.CenterLoader{
    background-color: #505050cc;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}