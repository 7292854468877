.galleryDivImg {
  width: 100px;
  height: 100px;
  position: relative;
}
.galleryDivImg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  border-radius: 10px;
}
.galleryDivImg button {
  position: absolute;
  top: -15px;
  right: 0;
}/*# sourceMappingURL=Profile.module.css.map */