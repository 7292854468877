@import '../color';

.btnNone {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.btnBlueIcon {
    color: $materialBlue;
}

.btnBlue {
    display: flex;
    justify-content: center;
    padding: 1em;
    background-color: $darkBlue;
    border-radius: 15px;
    color: white;
    font-size: medium;
    align-items: center;
    cursor: pointer;
    outline: none;
    text-decoration: none;
}

.btnBlueOuter {
    display: flex;
    justify-content: center;
    padding: .5rem 1rem;
    border-color: $materialBlue;
    background-color: transparent;
    border-radius: 15px;
    color: white;
    font-size: medium;
    align-items: center;
    cursor: pointer;
    outline: none;
}

.btnBlueOuterLink{
    display: flex;
    justify-content: center;
    padding: .5rem 1rem;
    background-color: transparent;
    border-radius: 15px;
    color: white;
    font-size: small;
    align-items: center;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    width: fit-content;
    border: 1px solid #2196f3;
}

.btnBlueOuter:hover,.btnBlueOuterLink:hover {
    color: white;
    background-color: $materialBlue;
}

.confirmBtnDialog {
    background-color: $materialBlue  !important;
}
.danger,.danger:hover {
    background-color: $red !important;
    color: white !important;
}

.rejectBtnDialog {
    color: $Name !important;
}

.btnLogout{
    color: white;
    display: flex;
    align-items: center;
    margin-inline-start: 1em;
}