.container {
  position: relative;
}

.input {
  position: relative;
  z-index: 10;
}

.customFileInput::-webkit-file-upload-button {
  visibility: hidden;
}

.customFileInput {
  color: transparent;
  border: 1px dashed white;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.icon {
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.icon svg {
  width: 10px;
}/*# sourceMappingURL=Upload.module.css.map */