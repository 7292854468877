@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body{
  background-color: black;
  font-family: 'Roboto', sans-serif;

}

/* .css-1pahdxg-control,
.select__menu,
.css-qc6sy-singleValue,
.css-1s2u09g-control, */
.css-1l8j5k8,
.css-1t1j96h-MuiPaper-root-MuiDialog-paper,
.css-1kvaj0b,
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper,
.css-4t3x6l-MuiPaper-root-MuiDrawer-paper,
.css-zxtyt4-MuiPaper-root-MuiAppBar-root{
  background-color: #1e2026 !important;
  color: white !important;
}
.css-13eg511-MuiButtonBase-root-MuiListItemButton-root.Mui-selected,.css-wqyty6.Mui-selected {
  background-color: #4e4f50a6 !important;
}

.p-fileupload .p-fileupload-content{
  background-color: #1e2026 !important;

}

.fc .fc-daygrid-day-frame,.fc-scrollgrid-sync-inner{
  background-color: #000000ad;
  color: white;
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event ,.fc .fc-daygrid-event-harness
{
  cursor: pointer;
}

.fc .fc-daygrid-day.fc-day-today{
  background-color: #ebcb8d;
}