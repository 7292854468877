.inputsDiv {
  position: relative;
}

.label {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;
  z-index: 1;
  font-size: small;
}

.inputs {
  width: 100%;
  background: transparent;
  border: 1px solid rgba(220, 220, 220, 0.4705882353);
  padding: 1rem 0.1rem;
  border-radius: 5px;
  color: white;
  z-index: 10;
  position: relative;
}

::-moz-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0; /* Firefox */
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0; /* Firefox */
}

.inputs:not(:-moz-placeholder-shown) {
  outline: none;
  border: 2px solid #90caf9;
}

.inputs:not(:placeholder-shown), .inputs:focus {
  outline: none;
  border: 2px solid #90caf9;
}
.inputs:not(:-moz-placeholder-shown) + .label {
  top: 0;
  z-index: 11;
  background-color: rgba(26, 32, 39, 0.8117647059);
  color: #90caf9;
}
.inputs:not(:placeholder-shown) + .label, .inputs:focus + .label {
  top: 0;
  z-index: 11;
  background-color: rgba(26, 32, 39, 0.8117647059);
  color: #90caf9;
}/*# sourceMappingURL=Inputs.module.css.map */