.container {
  color: white;
}

@media screen and (min-width: 1300px) {
  .container {
    width: 30%;
    margin: auto;
  }
}
@media screen and (min-width: 700px) and (max-width: 1300px) {
  .container {
    width: 50%;
    margin: auto;
  }
}
@media screen and (max-width: 700px) {
  .container {
    width: 80%;
    margin: 1.5em;
  }
}
.login {
  background-color: rgb(26, 32, 39);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: inherit;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  text-align: center;
  border-radius: 10px;
}/*# sourceMappingURL=Auth.module.css.map */