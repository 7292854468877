@import  "../../color";

.inputsDiv {
    position: relative;
   
}

.label {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
    z-index: 1;
    font-size: small;
}

.inputs {
    width: 100%;
    background: transparent;
    border: 1px solid #dcdcdc78;
    padding: 1rem 0.1rem;
    border-radius: 5px;
    color: white;
    z-index: 10;
    position: relative;

}



::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 0; /* Firefox */
  }

  .inputs:not(:placeholder-shown),.inputs:focus {
    outline: none;

    border:2px solid $materialBlue;
  
    +.label{
        top: 0;
        z-index: 11;
        background-color: #1a2027cf;
        color: $materialBlue;
    }
  }
